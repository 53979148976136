import { Tooltip as ReactTooltip } from 'react-tooltip';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

export interface HelpTooltipProps {
  value: string;
  place?: 'left' | 'right' | 'top' | 'bottom';
}

export function HelpTooltip({
  value,
  place = 'right',
}: HelpTooltipProps): JSX.Element {
  return (
    <>
      <button
        type="button"
        data-tip={value}
        data-place={place}
        data-background-color="#4b5563"
        data-text-color="#f3f4f6"
        data-border="true"
        data-border-color="#d1d5db"
        data-class="rounded-lg"
        className="relative inline w-4 h-4 p-0 m-0 ml-1 outline-none focus:outline-none"
      >
        <QuestionMarkCircleIcon className="w-4 h-4 text-gray-400" />
      </button>
      <ReactTooltip data-place={place} />
    </>
  );
}

export default HelpTooltip;
