/* eslint-disable prefer-spread */
import { classNames } from '../../utils';


/* eslint-disable-next-line */
export interface LoadingSkeletonProps { }

export function LoadingSkeleton(props: LoadingSkeletonProps) {
  const numFigures = 6; // Changer le nombre de figures ici

  const renderFigures = () => {
    const figures = [];
    for (let i = 0; i < numFigures; i++) {
      figures.push(
        <div
          key={i}
          className={`w-8 h-8 bg-blue-500 rounded-full animate-bounce mr-2`}
        ></div>
      );
    }
    return figures;
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white">
      <div className="flex items-center mb-4">{renderFigures()}</div>
    </div>
  );
}

export default LoadingSkeleton;
