
import Form from '../../components/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useContext } from 'react';
import { UserContext } from '../../context';
import FormInput from '../../components/FormInput';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { functions } from '../../firebase';
import { toast } from 'react-hot-toast';
/* eslint-disable-next-line */
export interface OrganizationProps { }

export function Organization(props: OrganizationProps) {
    const { merchant, user } = useContext(UserContext);
    const handleSubmit = (data: any) => {
        data.merchantId = user?.merchantId
        const updateMerchant = functions.httpsCallable('yengaPayUpdateMerchantProfile');
        toast.promise(updateMerchant(data).then(() => { }), {
            loading: "Mise à jour...",
            success: "Les informations marchant ont été mise à jour",
            error: (err) => `${err.message}`
        })
    }
    return (
        <>
            <div className="p-2">
                <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                        Retrouver ici toutes les information concernant votre compte marchant
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                        Vous pouvez mettre a jour les informations de votre compte marchant
                    </p>
                </div>
                <div className="mt-1 space-y-8 ">
                    <div className="flex flex-col p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md gap-2">
                        <p className='font-bold mb-2'>{merchant?.organizationName}</p>
                        <div className='flex items-center'>
                            <p> Votre ID de marchant</p>
                            <div className='flex h-4 bg-blue-900 font-bold text-white px-5 py-3 ml-4 items-center rounded-md mr-3'>
                                {merchant?.merchantId}
                            </div>
                            <button onClick={() => { navigator.clipboard.writeText(merchant?.merchantId ?? "").then(() => { toast.success("ID marchant copié"); }) }}>
                                <ClipboardDocumentCheckIcon className='h-6' />
                            </button>
                        </div>
                    </div>
                    {merchant && (
                        <Form
                            onSubmit={handleSubmit}

                            // formReturnAware={setGroupFormReturn}
                            form={{
                                mode: 'onChange',
                                resolver: yupResolver(
                                    yup.object().shape({
                                        organizationName: yup
                                            .string().required("Le nom de l'organisation est obligatoire"),
                                        ifuNumber: yup
                                            .string().required("Le numéro IFU est obligatoire"),
                                        website: yup
                                            .string(),
                                        address: yup
                                            .string(),
                                    })
                                ),
                                defaultValues: {
                                    organizationName: merchant?.organizationName,
                                    ifuNumber: merchant?.ifuNumber,
                                    website: merchant?.website,
                                    address: merchant?.address,

                                }
                            }}
                            submitButtonLabel={`Enregistrer les modifications`}
                        >
                            <div className="flex flex-col">
                                <div className="w-full">
                                    <FormInput
                                        type="text"
                                        name="organizationName"
                                        label="Nom de votre Organisation"
                                    />
                                </div>
                                <div className="w-full">
                                    <FormInput
                                        type="text"
                                        name="ifuNumber"
                                        label="Numéro IFU"
                                    />
                                </div>
                                <div className="w-full">
                                    <FormInput
                                        type="text"
                                        name="website"
                                        label="Site Web"
                                    />
                                </div>
                                <div className="w-full">
                                    <FormInput
                                        type="text"
                                        name="address"
                                        label="Addresse"
                                    />
                                </div>
                            </div>
                        </Form>
                    )}

                </div>
            </div>
        </>
    );
}

export default Organization;
