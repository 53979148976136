import MultiStepForm from "./multiStepForm";

function Register() {
    return <div className="flex justify-center">
        <MultiStepForm />
    </div>
}



export default Register