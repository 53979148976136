import Form from '../../components/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../context';
import FormInput from '../../components/FormInput';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { auth, functions } from '../../firebase';
function Profile() {
  const { user } = useContext(UserContext);



  const handleSubmit = (data: any) => {
    data.uid = auth.currentUser?.uid
    const updateUser = functions.httpsCallable('yengaPayUpdateUserProfile');
    toast.promise(updateUser(data).then(() => { }), {
      loading: "Mise à jour...",
      success: "Les informations utilisateur ont été mise à jour",
      error: (err) => `${err.message}`
    })
  }
  return (
    <>

      <div className="p-2">
        <div>
          <h2 className="text-lg font-medium leading-6 text-gray-900">
            Retrouver ici toutes les information concernant votre profil
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Vous pouvez mettre a jour votre profil
          </p>
        </div>
        <div className="mt-1 space-y-8 ">
          <div className="flex flex-col p-4 my-2 text-sm text-gray-700 bg-gray-100 rounded-md gap-2">
            <p className='font-normal mb-2'>Adresse Email</p>
            <p className='font-bold mb-2'>{user?.mailAddress}</p>
          </div>
          {user && (
            <Form
              onSubmit={handleSubmit}
              form={{
                resolver: yupResolver(
                  yup.object().shape({
                    name: yup
                      .string().required('Le nom est oblogatoire'),
                    // mailAddress: yup
                    //   .string().required('Le nom est oblogatoire'),
                  })
                ),

                defaultValues: {
                  name: user?.name,
                  // mailAddress: user?.mailAddress,
                }
              }}
              submitButtonLabel={`Enregistrer les modifications`}
            >
              <div className="flex flex-col">
                <div className="w-full">
                  <FormInput
                    type="text"
                    name="name"
                    label="Nom et Prénom"
                  />
                </div>
                {/* <div className="w-full">
                  <FormInput
                    type="text"
                    name="mailAddress"
                    label="Email"
                  />
                </div> */}
                {/* <div className="w-full">
                  <FormInput
                    type="text"
                    name="website"
                    label="Site Web"
                  />
                </div>
                <div className="w-full">
                  <FormInput
                    type="text"
                    name="address"
                    label="Addresse"
                  />
                </div> */}
              </div>
            </Form>
          )}

        </div>
      </div>
    </>);
}

export default Profile;
