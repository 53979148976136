import { Outlet } from 'react-router-dom';
// import backgroundImage from '../../../../assets/background-auth.jpg';

export function AuthLayout() {
  return (
    <div>
      <Outlet />
    </div>
    // <div className="relative flex justify-center min-h-full md:px-12 lg:px-0">
    //   <div className="relative z-10 flex flex-col flex-1 px-4 py-4 bg-white shadow-2xl sm:justify-center md:flex-none md:px-28">
    //     <div className="w-full max-w-md mx-auto sm:px-4 md:w-96 md:max-w-sm md:px-0">
    //       <Outlet />
    //     </div>
    //   </div>
    //   {/* <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
    //     <img
    //       className="absolute inset-0 object-cover w-full h-full"
    //       src={backgroundImage}
    //       alt=""
    //     />
    //   </div> */}
    // </div>
  );
}

export default AuthLayout;