import React, { useState, useContext } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Root from './pages/Root';
import VerifyEmail from './pages/Auth/VerifyEmail';
import AuthRoute from './components/privateRoute';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import AuthLayout from './Layout/AuthLayout';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import { UserContext } from './context';
import useStateGetter from "./hooks/useStateGetter";
import Transactions from './pages/Transactions';
import PageNotFound from './pages/404/PageNotFound';
import ForgetPassword from './pages/Auth/ForgotPassword';
import Organization from './pages/Organisation';
import CustomToaster from './components/CustomToaster';


function App() {
  const { user, setUser, merchant, setMerchant, isLoading } = useStateGetter();
  return (
    <>
      <UserContext.Provider value={{
        isLoading,
        user,
        setUser,
        merchant,
        setMerchant
      }}>
        <Routes>
          <Route path='/auth' element={<AuthLayout />}>
            <Route
              path="login"
              element={
                <AuthRoute path="/auth/login">
                  <Login />
                </AuthRoute>
              }
            />
            <Route
              path="register"
              element={
                <AuthRoute path="/auth/register">
                  <Register />
                </AuthRoute>
              }
            />
            <Route
              path="verify-email"
              element={
                <AuthRoute path="/auth/verify-email">
                  <VerifyEmail />
                </AuthRoute>
              }
            />
            <Route
              path="forgot-password"
              element={
                <AuthRoute path="/auth/forgot-password">
                  <ForgetPassword />
                </AuthRoute>
              }
            />
          </Route>
          <Route
            path='/'
            element={
              <AuthRoute path='/'>
                <Root />
              </AuthRoute>
            }>
            <Route
              path='dashboard'
              element={
                <AuthRoute path='/dashboard'>
                  <Dashboard />
                </AuthRoute>
              }
            />
            <Route
              path='profile'
              element={
                <AuthRoute path='/profile'>
                  <Profile />
                </AuthRoute>
              }
            />
            <Route
              path='transactions'
              element={
                <AuthRoute path='/transactions'>
                  <Transactions />
                </AuthRoute>
              }
            />
            <Route
              path='organization'
              element={
                <AuthRoute path='/organization'>
                  <Organization />
                </AuthRoute>
              }
            />
            <Route path="" element={<Navigate to="dashboard" />} />
          </Route>
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </UserContext.Provider>
      <CustomToaster />
    </>
  );
}

export default App;
