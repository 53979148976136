import { useEffect, useState } from "react";
import { MerchantInterface, UserInterface } from "../interfaces";
import { auth, db } from "../firebase";
import firebase from "firebase/compat/app";
import {
  YENGAPAY_MERCHANTS_COLLECTION,
  YENGAPAY_USERS_COLLECTION,
} from "../config";
export default function useStateGetter() {
  const [user, setUser] = useState<UserInterface>();
  const [merchant, setMerchant] = useState<MerchantInterface>();
  const [isLoading, setIsLoading] = useState(true);

  const getMerchant = async (id: string) => {
    await db
      .collection(YENGAPAY_MERCHANTS_COLLECTION)
      .doc(id)
      .get()
      .then((response) => {
        setMerchant(response.data() as MerchantInterface);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const getUser = async (id: string) => {
    const reposne = await db
      .collection(YENGAPAY_USERS_COLLECTION)
      .doc(id)
      .get()
      .then((response) => {
        setUser(response.data() as UserInterface);
        return response.data();
      })
      .catch((err) => { });
    return reposne;
  };

  useEffect(() => {
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (currentUser) => {
        if (currentUser?.uid) {
          await getUser(currentUser?.uid).then((response) => {
            getMerchant(response?.merchantId);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
          setUser(undefined);
        }
      });
    // Nettoyer l'abonnement lors du démontage
    return () => unsubscribe();
  }, []);
  return {
    isLoading,
    user,
    setUser,
    merchant,
    setMerchant
  };
}
