import ReactECharts from 'echarts-for-react';

type Props = {
  dataArray: Array<number>
  dateArray: Array<String>
}

const BarChart = ({ dataArray, dateArray }: Props) => {
  const option = {
    title: {
      text: ''
    },
    tooltip: {},
    legend: {
      data: ['Transactions']
    },
    xAxis: {
      data:dateArray
    },
    yAxis: {},
    series: [{
      name: 'Transactions',
      type: 'bar',
      data: dataArray
    }]
  };

  return <div>
    <ReactECharts
      option={option}
      style={{ height: 600, overflow: 'hidden' }}
      opts={{ renderer: 'svg' }}
    /></div>
};

export default BarChart;