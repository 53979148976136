import { createContext } from "react";
import { MerchantInterface, UserInterface } from "../interfaces";

export const UserContext = createContext<{
  user: UserInterface | undefined;
  setUser: any;
  merchant: MerchantInterface | undefined;
  setMerchant: any;
  isLoading: boolean;
}>({
  user: undefined,
  setUser: null,
  merchant: undefined,
  setMerchant: null,
  isLoading: false,
});
