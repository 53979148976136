
import { Link, useLocation } from 'react-router-dom';

// import GoogleAuthButton from '../../../components/GoogleAuthButton';


import toast from 'react-hot-toast';

import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { auth } from '../../../firebase';
import firebase from 'firebase/compat/app';
import Alert from '../../../components/Alert';
/* eslint-disable-next-line */
export interface VerifyEmailProps { }

export function VerifyEmail(props: VerifyEmailProps) {


  const location = useLocation();
  const email = location?.state?.email
  const reason = location?.state?.reason
  const [emailResend, setEmailResend] = useState(false)
  const [error, setnError] = useState<any>()


  const resentEmail = () => {
    try {

      reason === "registration" ? auth.currentUser?.sendEmailVerification() : auth.sendPasswordResetEmail(email); setEmailResend(true);

    } catch (error) {
      setnError(error)
    }
  }

  return (
    <>

      <div className="fmin-h-full px-6 py-16 bg-white sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <img src={IkoddiLogo} alt="" className='w-full' /> */}
          {/* <h2
            data-cy="page-title"
            className="mt-2 text-3xl font-extrabold text-center text-gray-900"
          >
            {t('register.createFreeAccount')}
          </h2>
          <p className="mt-2 text-sm text-center text-gray-600">
            {t('register.or')}{' '}
            <Link
              to={'/auth/login'}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              {t('register.loginToYourAccount')}
            </Link>
          </p> */}
          {error && (
            <Alert
              type="danger"
              title="Erreur"
              content={error.message}
            />
          )}
        </div>
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md mt">
          <div className="py-2">
            <div className="max-w-sm p-6 bg-white dark:bg-gray-800 dark:border-gray-700">
              <p className='font-bold text-lg text-black text-center my-10'>YENGAPAY</p>
              {reason === "registration" ? <>
                <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Mail de vérification {emailResend ? "renvoyé" : "envoyé"}</h5>
                <p className="font-normal text-gray-500 dark:text-gray-400">Nous avons {emailResend ? "renvoyé" : "envoyé"} un mail de vérification a l'addresse mail : </p>
                <p className="inline-flex items-center text-indigo-600 hover:underline"><EnvelopeIcon className='h-5 text-kreezus-color mr-2' />{auth.currentUser?.email} </p>
                <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">Vérifiez votre email pour continuer</p>
              </> :
                <>
                  <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Mail de réinitialisation du mot de passe {emailResend ? "renvoyé" : "envoyé"}</h5>
                  <p className="font-normal text-gray-500 dark:text-gray-400">Nous avons {emailResend ? "renvoyé" : "envoyé"} un mail de réinitialisation du mot de passe a l'addresse mail : </p>
                  <p className="inline-flex items-center text-indigo-600 hover:underline"><EnvelopeIcon className='h-5 text-kreezus-color mr-2' />{reason === "registration" ? auth.currentUser?.email : email} </p>
                  <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">Vérifiez votre email pour continuer</p>
                </>
              }
              {!emailResend ? <button onClick={() => {resentEmail() }} className='flex mx-auto mt-5 text-gray-500 hover:bg-blue-900 border 
              border-transparent hover:transition-colors hover:duration-500 rounded-md hover:shadow-sm py-2 px-4 justify-center text-sm font-normal hover:text-white
               focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800'>Renvoyer</button> :
                <button className='flex mx-auto mt-5 text-gray-500 py-2 px-4 justify-center text-sm font-normal'>Email renvoyé</button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VerifyEmail;
