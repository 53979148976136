
import { Fragment, useEffect, useState } from "react";
import FormInput from "../../../components/FormInput";
import Alert from "../../../components/Alert";
import { auth, googleAuthProvider } from "../../../firebase";
import { signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth'
import Form from "../../../components/Form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import firebase from "firebase/compat/app";
import { Navigate, useNavigate } from "react-router-dom";


function ForgetPassword() {
    const [signInError, setSignInError] = useState<any>()
    const navigate = useNavigate();
    const changePassword = async (email: any) => {
        try {
            auth.sendPasswordResetEmail(email).then(() => {
                navigate('/auth/verify-email', {
                    state: {
                        reason: "forgotPassword",
                        email
                    }
                })
            }).catch((error) => {
                setSignInError(error)
            })
        } catch (err) { }
    }


    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 items-center mt-12">
            <div className="max-w-sm flex flex-col w-full">
                <div className="sm:mx-auto sm:w-full sm:max-w-md justify-center">
                    <div className="flex justify-center">

                    </div>
                    <h2 className="mt-6 mb-4 text-2xl font-bold tracking-tight text-gray-900 text-center">
                        Réinitialiser le mot de passe
                    </h2>
                    <h2 className="mt-6 mb-4 text-sm font-normal tracking-tight text-gray-900 text-center">
                        Un email vous sera envoyé pour changer votre votre mot de passe.

                    </h2>
                </div>

                {signInError && (
                    <Alert
                        type="danger"
                        title="Erreur"
                        content={signInError.message}
                    />
                )}
                <Form
                    submitButtonFullWidth
                    onSubmit={changePassword}
                    form={{
                        resolver: yupResolver(
                            yup.object().shape({
                                email: yup
                                    .string()
                                    .email()
                                    .required(`L'adresse email est requis`),
                            })
                        ),
                    }}
                    submitButtonLabel="Envoyer"
                >
                    <FormInput name="email" label="Email" type="email" />
                </Form>

            </div>


        </div>
    );
}

export default ForgetPassword

