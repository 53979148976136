import { useEffect, useState } from "react";
import BarChart from "../../components/BarChart";
import CircleChart from "../../components/CircleChart";
import { db, auth } from "../../firebase";
import { YENGAPAY_MERCHANTS_COLLECTION } from "../../config";
import { useContext } from "react";
import { UserContext } from "../../context";



const Dashboard = () => {
  const { merchant } = useContext(UserContext);
  const [previousDay, setPreviousDay] = useState<number>(30)
  const now = new Date();
  const lastDays = new Date();
  lastDays.setDate(now.getDate() - previousDay);
  const transactionsGroupedByDay: any = {};
  const transactionsGroupedByOperator: any = {};
  const [dayDateArrayForBarChart, setDayDateArrayForBarChart] = useState<Array<string>>([])
  const [dayDataArrayForBarChart, setDataArrayForBarChart] = useState<Array<number>>([])
  const [dataForCircleChart, setDataForCircleChart] = useState<Array<Map<any, String | number>>>([])
  const [opreatorForCircleChart, setOpreatorForCircleChart] = useState<Array<String>>([])
  const production = localStorage.getItem('production')
  const [envState, setEnvState] = useState(production === "true" ? "prod" : "test")

  const getAndSetData = (querySnapshot: any) => {
    const barChartDayArray: any = []
    const barChartDataArray: any = []
    const circleChartDataArray: any = []
    const circleChartOperatorArray: any = []

    querySnapshot.forEach((doc: any) => {
      console.log(doc.data())
      const transactionDate = doc.data().transactionDate;
      const newDate = new Date(transactionDate);
      const keyDate = newDate.toLocaleDateString('fr')

      //For Bar chart
      if (!transactionsGroupedByDay[keyDate]) {
        transactionsGroupedByDay[keyDate] = [doc.data()]
      } else {
        transactionsGroupedByDay[keyDate].push(doc.data())
      }

      //For Circle chart
      if (!transactionsGroupedByOperator[doc.data().paymentSource]) {
        transactionsGroupedByOperator[doc.data().paymentSource] = [doc.data()]
      } else {
        transactionsGroupedByOperator[doc.data().paymentSource].push(doc.data())
      }
    });
    // console.log("DateList =>", transactionsGroupedByDay)
    //For Bar chart
    for (let element in transactionsGroupedByDay) {
      let dayAmount = 0;
      barChartDayArray.push(element);
      for (let data of transactionsGroupedByDay[element]) {
        dayAmount = dayAmount + parseFloat(`${data.amount}`)
      }
      barChartDataArray.push(dayAmount)
    }

    //For Circle chart
    for (let element in transactionsGroupedByOperator) {
      let dayAmount = 0;
      for (let data of transactionsGroupedByOperator[element]) {
        dayAmount = dayAmount + parseFloat(data.amount);
      }
      circleChartDataArray.push({ value: dayAmount, name: element });
      circleChartOperatorArray.push(element);
    }

    for (let i = 0; i < barChartDayArray.length - 1; i++) {
      const prevDate = new Date(`${barChartDayArray[i].split("/")[2]},${barChartDayArray[i].split("/")[1]},${barChartDayArray[i].split("/")[0]}`);
      const nextDate = new Date(`${barChartDayArray[i + 1].split("/")[2]},${barChartDayArray[i + 1].split("/")[1]},${barChartDayArray[i + 1].split("/")[0]}`);

      if ((nextDate.getDate() - prevDate.getDate()) !== 1) {
        const missingDate = new Date(prevDate);
        missingDate.setDate(prevDate.getDate() + 1);

        barChartDayArray.splice(i + 1, 0, `${missingDate.toLocaleDateString()}`);
        barChartDataArray.splice(i + 1, 0, 0);
      }

    }
    setDataArrayForBarChart(barChartDataArray);
    setDayDateArrayForBarChart(barChartDayArray);
    setDataForCircleChart(circleChartDataArray);
    setOpreatorForCircleChart(circleChartOperatorArray)
  }

  const listenEnvState = () => {
    if (production === "true") {
      setEnvState("prod")
    } else {
      setEnvState("test")
    }
  }

  useEffect(() => {
    try {
      db.collection("yengapay_merchants")
        //Mettre 00001 comme id marchant pour le test
        .doc(`00001`)
        .collection("payments").doc(envState).collection("transactions").where('createdAt', '>', lastDays)
        .get()
        .then((querySnapshot) => {
          getAndSetData(querySnapshot)
        });
      listenEnvState();
    } catch (error) {
      console.log("ERROR=>", error)
    }
  }, [previousDay])
  return <div>
    <div className="max-w-md">
      <label htmlFor="period" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Sélectionner une période</label>
      <select
        id="period" defaultValue={"30"} onChange={(event) => { setPreviousDay(parseInt(event.target.value)) }} className="bg-gray-50 border border-gray-300
       text-gray-900 text-sm focus:ring-blue-500
        focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700
         dark:border-gray-600 dark:placeholder-gray-400 dark:text-white
          dark:focus:ring-blue-500 dark:focus:border-blue-500">
        <option value="60">60 derniers jours</option>
        <option value="30">30 derniers jours</option>
        <option value="14">14 derniers jours</option>
        <option value="7">7 derniers jours</option>
      </select>
    </div>
    <BarChart dateArray={dayDateArrayForBarChart} dataArray={dayDataArrayForBarChart} />
    <CircleChart circleChartDataArray={dataForCircleChart} circleChartOperatorArray={opreatorForCircleChart} />
  </div>
};

export default Dashboard;