
import { useState } from "react";
import FormInput from "../../../components/FormInput";
import Alert from "../../../components/Alert";
import { auth, db, functions } from "../../../firebase";
import { createUserWithEmailAndPassword } from 'firebase/auth'
import Form from "../../../components/Form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import firebase from "firebase/compat/app";
import { httpsCallable, getFunctions } from 'firebase/functions'
import { useNavigate } from "react-router-dom";
import { YENGAPAY_MERCHANTS_COLLECTION } from "../../../config";
import { toast } from "react-hot-toast";
import { MerchantInterface, UserInterface } from "../../../interfaces";
function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default function MultiStepForm() {
    const [data, setData] = useState<any>()
    const navigate = useNavigate();
    const [signInError, setSignInError] = useState<any>()


    const verifyIfCustomMerchantIdNotExistBeforeCreateIt = async (collectionName: string, data: any) => {
        const merchantId = `${Math.floor(
            Math.pow(10, 5) + Math.random() * 9 * Math.pow(10, 5)
        )}`;
        const createMerchant = functions.httpsCallable('yengaPayCreateMerchantProfile');
        db.collection(collectionName)
            .doc(merchantId)
            .get().then((snapshot) => {
                if (snapshot.exists) {
                    verifyIfCustomMerchantIdNotExistBeforeCreateIt(collectionName, data);
                } else {
                    const merchantData: MerchantInterface = { organizationName: data.organizationName, ifuNumber: data.ifuNumber, address: data.address, website: data.website, merchantId: merchantId, uid: auth.currentUser!.uid }
                    const userData: UserInterface = { name: data.name, mailAddress: data.mailAddress, merchantId: merchantId, uid: auth.currentUser!.uid }
                    createMerchant(merchantData).then((response) => {
                        const updateUser = functions.httpsCallable('yengaPayUpdateUserProfile');
                        try {
                            updateUser(userData).then((response) => {
                                //Send email verification
                                auth.currentUser?.sendEmailVerification();
                                navigate('/auth/verify-email', {
                                    state: {
                                        reason: "registration"
                                    }
                                })
                            });
                        } catch (error) {
                            console.log(error);
                            setSignInError(error)
                        }

                    });
                }
            }).catch((err) => {
                setSignInError(err);
                console.log(err)
            });

    }

    const createAppUserWithEmailAndPassword = async (data: any) => {


        createUserWithEmailAndPassword(auth, data.mailAddress, data.password).then(() => {
            delete data.password;
            delete data.confirmPassword;
            //Create merchant with unique custom id
            verifyIfCustomMerchantIdNotExistBeforeCreateIt(YENGAPAY_MERCHANTS_COLLECTION, data).then(() => {

            })

        }).catch((error) => {
            setSignInError(error)
        })

    }

    const goToNextStepIfIsValidInfo = (data: any) => {
        setData(data)
        setStep(1);
    }
    const handleSubmit = (data: any) => {
        setData(data)
        return toast.promise(createAppUserWithEmailAndPassword(data).then((response) => { }),
            {
                loading: "Création de votre compte...",
                success: "Compte créé avec succès",
                error: (err) => `${err.response.data.message}`,
            })

    }
    const [step, setStep] = useState(0)

    return (
        <div className="w-full max-w-md px-2 py-12 sm:px-0 mt-12">
            <div className="sm:mx-auto sm:w-full sm:max-w-md justify-center">
                <div className="flex justify-center">
                </div>
                <h2 className="mt-6 mb-4 text-2xl font-bold tracking-tight text-gray-900 text-center">
                    Inscription Yenga Pay
                </h2>
            </div>
            {signInError && (
                <Alert
                    type="danger"
                    title="Erreur"
                    content={signInError.message}
                />
            )}
            <div className='flex space-x-1 rounded-xl bg-blue-900/20 p-1 mt-3'>
                <div className={classNames(
                    'w-full transition-colors duration-500 text-center rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    step === 0
                        ? 'bg-white shadow'
                        : 'text-blue-100 hover:bg-white/[0.12]'
                )}>
                    Profil
                </div>
                <div className={classNames(
                    'w-full transition-colors duration-500 text-center rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                    step === 1
                        ? 'bg-white shadow'
                        : 'text-blue-100 hover:bg-white/[0.12]'
                )}>
                    Organisation
                </div>
            </div>
            {
                step === 0
                    ? <Form
                        onSubmit={goToNextStepIfIsValidInfo}
                        form={{
                            resolver: yupResolver(
                                yup.object().shape({
                                    name: yup
                                        .string()
                                        .required(`Le nom est obligatoire`),
                                    mailAddress: yup
                                        .string()
                                        .email()
                                        .required(`L'adresse email est requis`),
                                    password: yup
                                        .string()
                                        .required(`Le mot de passe est requis`)
                                        .min(6,
                                            `Le mot de passe doit avoir au moins 6 caractères`,
                                        ),
                                    confirmPassword: yup
                                        .string()
                                        .required(`La confirmation du mot de passe est requise`)
                                        .oneOf(
                                            [yup.ref('password')],
                                            `Les mots de passes ne correspondes pas`
                                        ),

                                })
                            ),
                            defaultValues: {
                                name: data?.name,
                                mailAddress: data?.mailAdress,
                                password: data?.password,
                                confirmPassword: data?.confirmPassword,
                            }
                        }}
                        submitButtonLabel="Continuer"

                    >
                        <FormInput
                            name="name"
                            label="Votre Nom"
                            type="text"
                            placeholder="Raoul Compaore"
                        />
                        <FormInput
                            name="mailAddress"
                            label="Votre adresse mail"
                            type="text"
                            placeholder="raoul.compaore@kreezus.com"
                        />
                        <FormInput
                            name="password"
                            label="Mot de passe"
                            type="password"
                        />
                        <FormInput
                            name="confirmPassword"
                            label="Confirmer le mot de passe"
                            type="password"
                        />
                    </Form> :
                    <Form
                        button={<button onClick={() => { setStep(0); }} className="text-blue-700  hover:bg-blue-900 hover:text-white border border-blue-700 
                focus:ring-4 focus:outline-none  border-transparent transition-colors duration-500 focus:ring-blue-500 font-medium rounded-md text-sm px-5 py-2 text-center mr-2 shadow-sm">Précedent</button>}
                        onSubmit={handleSubmit}
                        form={{
                            resolver: yupResolver(
                                yup.object().shape({
                                    organizationName: yup
                                        .string()
                                        .required(`Le nom de l'organisation est obligatoire`),
                                    ifuNumber: yup
                                        .string()
                                        .required(`Le numéro IFU de l'organsation est obligatoire`),
                                    address: yup
                                        .string(),
                                    website: yup
                                        .string()
                                })
                            ),
                            defaultValues: {
                                organizationName: data?.organizationName,
                                ifuNumber: data?.ifuNumber,
                                address: data?.address,
                                website: data?.website,
                            }
                        }}
                        submitButtonLabel="Créer le compte"
                    >
                        <FormInput
                            name="organizationName"
                            label="Nom de l'organisation"
                            type="text"
                            placeholder="Kreezus SAS"
                        />
                        <FormInput
                            name="ifuNumber"
                            label="Numéro IFU de l'organisation"
                            type="text"
                            placeholder="BF99UUYI9IHJ"

                        />
                        <FormInput
                            optional
                            name="address"
                            label="Addresse de l'organisation"
                            type="text"
                            placeholder="Ouagadougou, Burkina Faso"

                        />
                        <FormInput
                            optional
                            name="website"
                            label="Site web"
                            type="text"
                            placeholder="www.kreezus.com"

                        />
                    </Form>
            }
            <p className="text-xs flex justify-center mt-4">Vous avez déja un compte ?<p className="text-blue-400 ml-2 hover:underline hover:cursor-pointer text-xs" onClick={() => { navigate('/auth/login') }}>Connectez vous !</p></p>

        </div >
    )
}
