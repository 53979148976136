import { auth, googleAuthProvider, db } from "../firebase";
import { useUserCred } from "../hooks/useUserCrud";
export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export function unwindObject(obj: Record<string, any>, field: string): any {
  if (Object.keys(obj).length === 0) {
    return undefined;
  }
  //The fields passed in might be "." delimmeted, so i
  //Use a loop to enter the object and get out the final needed value

  //e,g {player:{me:{she:"woman"}}}
  //To get the value woman, the field should be
  //"player.me.she"
  const fields = field.split(".");
  const field_length = fields.length;
  if (field_length <= 1) {
    return obj[field] as string;
  }

  let recuObje: any = { ...obj };

  let rolled_time = 0;

  while (recuObje && rolled_time < field_length) {
    recuObje = recuObje[fields[rolled_time]];

    rolled_time++;
  }

  /*
    to understand this while loop
    assume a length of array is called things with three elements
    things 3 (0,1,2)
    n = 0
    i) things[0]  n=1
    ii) things[1]  n=2
    ii) things[2]  n=3
    */
  return recuObje;
}
