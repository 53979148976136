import { auth, db } from "../../firebase";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  IRow,
  RowRenderFunction,
} from "../../components/FirestoreTable";
import { CheckCircleIcon, MagnifyingGlassIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import { YENGAPAY_MERCHANTS_COLLECTION } from "../../config";
// import orangeMoneyLogo from "../../images/mobilemoney/orange.png";
// import onatelMoneyLogo from "../../images/mobilemoney/onatel.png";
// import corisMoneyLogo from "../../images/mobilemoney/corism.png";
import { UserContext } from "../../context";



function Transactions() {
  const production = localStorage.getItem('production')
  const [word, setWord] = useState("");
  const [selectedOperator, setSelectedOperator] = useState("all-operator");
  const [searchWord, setSearchWord] = useState("");
  const [firstDate, setFirstDate] = useState<any>("");
  const [secondaryDate, setSecondaryDate] = useState<any>("");
  const [envState, setEnvState] = useState(production === "true" ? "prod" : "test")
  const { user } = useContext(UserContext)
  const columns: IColumn[] = [

    {
      field: "createdAt",
      label: "Date",
    },
    {
      field: "paymentSource",
      label: "Source de paiement",
    },

    {
      field: "amount",
      label: "Montant",
    },
    {
      field: "customerID",
      label: "Numéro de paiement",
    },
    {
      field: "apiEnv",
      label: "Environnement",
    },
    {
      field: "paymentMessage",
      label: "Message de paiement",
    },
    {
      field: "paymentSourceTransactionID",
      label: "ID de la transaction",
    },
    {
      field: "paymentStatus",
      label: "Statut",
    },
  ];
  const renderRow: RowRenderFunction = (
    row: IRow,
    column: IColumn,
    value: any
  ) => {
    switch (column.field) {
      case "createdAt":
        return <Moment locale="fr" format="L LT">{value.toDate() as Date}</Moment>;
      case "amount":
        return <span>{`${value} FCFA`}</span>;
      case "apiEnv":
        return <span>{value}</span>;
      case "customerID":
        return <span>{value}</span>;
      case "paymentStatus":
        return value !== "ERROR" ? (
          <CheckCircleIcon className="w-6 h-6 mx-auto text-green-500" />
        ) : (
          <XCircleIcon className="w-6 h-6 mx-auto text-red-500" />
        );
      case "paymentMessage":
        return <span>{cutMessage(value as string)}</span>;
      case "paymentRef":
        return <span>{value}</span>;
      case "paymentSource":
        return <span>{value}</span>;
      case "beneficiaryNumber":
        return (
          <>
            <p>{value}</p>
            {row["beneficiaryName"] && (
              <p className="text-sm text-gray-400">{row["beneficiaryName"]}</p>
            )}
          </>
        );
      default:
        return value as string;
    }
  };
  const cutMessage = (value: string): string => {
    const length = 30;
    let newFormat = "";
    if (value.length > 30) {
      for (let i = 0; i < length; i++) {
        if (value[i] !== undefined) {
          newFormat = newFormat.concat(value[i]);
        }
      }
      return newFormat.concat("...")
    } else {
      return value
    }

  }
  const handleChangeSearchWordValue = (event: any) => {
    setWord(event.target.value);
    if (event.target.value.length === 0) { applySearch() }
  }

  const applySearch = () => {
    setSearchWord(word)
  }

  const listenEnvState = () => {
    if (production === "true") {
      setEnvState("prod")
    } else {
      setEnvState("test")
    }
  }
  useEffect(() => {
    listenEnvState()
  }, [])

  return (
    <div className="px-4 sm:px-6 lg:px-8 w-full">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto pb-4">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Transactions
          </h1>
          <p className="mb-4 text-base text-gray-700">
            Vous trouverez ici l'ensemble des transactions de vos clients.
          </p>
          <div className="flex items-center mt-2 max-w-md">
            <div className="relative w-full flex items-center">
              <input type="text" onChange={handleChangeSearchWordValue} id="table-search" className="block p-2 pl-10 text-sm text-gray-900 rounded-lg w-full bg-gray-200 focus:ring-gray-50 focus:border-gray-50 border-none" placeholder="Recherchez par numéro: 60606060" />
              <button onClick={() => { applySearch() }} className="absolute right-0 flex items-center font-medium rounded-md p-2 bg-blue-800 hover:bg-blue-900 shadow-sm">
                <MagnifyingGlassIcon className="text-white h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
        <div className='flex flex-col pb-4'>
          <p className="mb-2 text-xs font-medium text-gray-700">
            Triez par mois et par année
          </p>
          <div className='flex gap-5'>
            <input onChange={(event) => { setFirstDate(event.target.value); }} className="text-gray-800 bg-gray-200 px-2 py-1 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-700" type="date" />
            <input onChange={(event) => { setSecondaryDate(event.target.value); }} className="text-gray-800 bg-gray-200 px-2 py-1 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-700" type="date" />
          </div>
          <div className='mt-3'>
            <p className="mb-2 text-xs font-medium text-gray-700">
              Triez en fonction de l'operateur
            </p>
            <div className='flex gap-5'>
              <select defaultValue="all-operator" onChange={(event: any) => {
                setSelectedOperator(event.target.value);
              }} className="py-1.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-emerald-500 focus:border-emerald-500 w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 block dark:text-white pr-10 dark:focus:ring-emerald-500 dark:focus:border-emerald-500">
                <option value="all-operator" >Tout</option>
                <option value="MoovMoneyAPI" >Moov Money</option>
                <option value="OrangeMoneyAPI" >Orange Money</option>
                <option value="PayPalAPI" >PayPal</option>
                <option value="CorisMoneyAPI">Coris Money</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <FirestoreTable
        collectionRef={db
          .collection("yengapay_merchants")
          // Use "00001" as doc id to get test transactions
          //.doc(user?.merchantId)
          .doc("00001")
          .collection("payments").doc(envState).collection("transactions")}
        findByNumber={searchWord}
        findByDate={
          (firstDate !== "" && secondaryDate !== "") ?
            {
              startDate: firstDate,
              endDate: secondaryDate
            } : undefined
        }
        // findByTransactionId={}
        findByTransactionSource={selectedOperator === "all-operator" ? undefined : selectedOperator}
        condensed
        columns={columns}
        rowRender={renderRow}
        paginate={{ pageIndex: 0, limit: 10, sort: "createdAt:desc" }}
      />
    </div>
  );
}

export default Transactions;
