import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';

type Props = {
  circleChartDataArray: Array<Map<any, any>>
  circleChartOperatorArray: Array<String>
}

const CircleChart = ({ circleChartDataArray,circleChartOperatorArray }: Props) => {
  const option = {
    title: {
      text: "Repartitions des transactions en fonction de l'opérateur",
      subtext: '',
      x: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    legend: {
      orient: 'vertical',
      left: 'left',
      data: circleChartOperatorArray
    },
    series: [
      {
        name: 'Part',
        type: 'pie',
        radius: '55%',
        center: ['50%', '60%'],
        data: circleChartDataArray,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          }
        }
      }
    ]
  };

  const [count, setCount] = useState(0);

  //   function onChartReady(echarts) {
  //     console.log('echarts is ready', echarts);
  //   }

  //   function onChartClick(param, echarts) {
  //     console.log(param, echarts);
  //     setCount(count + 1);
  //   };

  //   function onChartLegendselectchanged(param, echarts) {
  //     console.log(param, echarts);
  //   };

  return (
    <>
      <ReactECharts
        option={option}
        style={{ height: 400, overflow: 'hidden' }}
      // onChartReady={onChartReady}
      // onEvents={{
      //   'click': onChartClick,
      //   'legendselectchanged': onChartLegendselectchanged
      //  }}
      />
      {/* <div>Click Count: {count}</div>
      <div>Open console, see the log detail.</div> */}
      <div>Part des transactions par opérateur.</div>
      <div>En fonction de la période définie</div>
    </>
  );
};

export default CircleChart;