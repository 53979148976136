import { NavLink, Outlet } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import { auth } from "../../firebase";
import StyledFirebaseAuth from "../../components/StyledFirebaseAuth";

import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  Square3Stack3DIcon,
  XMarkIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  UserGroupIcon
} from "@heroicons/react/24/outline";
import { classNames } from "../../utils";
import { toast } from "react-hot-toast";

const sidebarNavigation = [
  {
    name: "Dashboard",
    path: "dashboard",
    icon: HomeIcon
  },
  {
    name: "Transactions",
    path: "transactions",
    icon: Square3Stack3DIcon,
  },
  {
    name: "Organisation",
    path: "organization",
    icon: UserGroupIcon,
  },
  //   {
  //     name: "Factures",
  //     path: "invoices",
  //     icon: DocumentDuplicateIcon,
  //   },
];
const userNavigation = [{ name: "Votre profil", path: "profile" }];

export interface LayoutProps {
  dummy?: string;
}

function Root(props: LayoutProps) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const production = localStorage.getItem("production");
  const setLocalStorageValue = () => {
    if (production === 'true') {
      localStorage.setItem('production', 'false');
      setCheckState(false);
      localStorage.setItem('prevProdState', "true")
    }
    else {
      localStorage.setItem('production', 'true');
      setCheckState(true);
      localStorage.setItem('prevProdState', "false")
    }
    window.location.reload();
  }
  useEffect(() => {
    const prevProdState = localStorage.getItem('prevProdState')
    if (prevProdState !== production) {
      if (production === "true") {
        toast.success("Vous êtes maintenant en mode PRODUCTION");
        localStorage.setItem('prevProdState', "true")
      }
      else {
        toast.success("Vous êtes maintenant en mode TEST");
        localStorage.setItem('prevProdState', "false")
      }
    }
  }, [])
  const [checkState, setCheckState] = useState(production === "true" ? true : false)
  return (
    <div className="flex h-screen overflow-hidden bg-white dark:bg-white">
      {/* Narrow sidebar */}
      <div className="hidden w-64 overflow-y-auto bg-blue-950 md:block">
        <div className="flex w-full flex-col items-center py-6">
          <div className="flex flex-shrink-0 items-center">
            {/* <a href="https://yenga.app" target={"_blank"} rel="noreferrer">
                <img
                  className="h-16 w-auto"
                  src={yengaLogoTransparent}
                  alt="Yenga"
                />
              </a> */}
          </div>
          <div className="mt-6 w-full flex-1 space-y-1 px-10">
            <div className="my-12">
              <p className="font-bold text-base text-white text-center">YENGAPAY</p>
            </div>
            {sidebarNavigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.path}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-white text-black"
                      : "text-white transition ease-in-out delay-100 hover:bg-white hover:text-black",
                    "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <item.icon
                      className={classNames(
                        isActive
                          ? "text-black"
                          : "text-white group-hover:text-black",
                        "transition ease-in-out delay-100 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="mt-2">{item.name}</span>
                  </>
                )}
              </NavLink>
            ))}
            <div></div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20 md:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex max-w-xs">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >

              <Dialog.Panel className="relative flex flex-1 flex-col bg-blue-950 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-32 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                {/* <div className="flex flex-shrink-0 items-center px-4 justify-center">
                    <a
                      href="https://yenga.app"
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <img
                        className="h-16 w-auto"
                        src={yengaLogoTransparent}
                        alt="Yenga"
                      />
                    </a>
                  </div> */}
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">
                      {sidebarNavigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.path}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-white text-black"
                                : "text-white hover:bg-white hover:text-black",
                              "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                            )
                          }
                        >
                          {({ isActive }) => (
                            <>
                              <item.icon
                                className={classNames(
                                  isActive
                                    ? "text-black"
                                    : "text-white group-hover:text-black",
                                  "mr-3 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              <span className="">{item.name}</span>
                            </>
                          )}
                        </NavLink>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Content area */}
      <div className="flex flex-col flex-1 w-0 mx-auto">
        <div className="relative z-10  flex flex-shrink-0 h-16 bg-white border-b border-gray-200 dark:bg-white dark:border-bg-white md:pr-6">
          <button
            type="button"
            className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 justify-end px-4 sm:px-6">
            {/* <div className="flex flex-1">
                <form className="flex w-full md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search all files
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center">
                      <MagnifyingGlassIcon
                        className="h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      name="search-field"
                      id="search-field"
                      className="h-full w-full border-transparent py-2 pl-8 pr-3 text-base text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0"
                      placeholder="Search"
                      type="search"
                    />
                  </div>
                </form>
              </div> */}
            <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
              {/* Profile dropdown */}
              <span className="-mr-4 text-sm font-medium text-gray-900 dark:text-gray-300">Test</span>
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  onChange={() => { setLocalStorageValue() }}
                  checked={checkState}
                  className="sr-only peer"
                />
                <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ml-1.5 text-sm font-medium text-gray-900 dark:text-gray-300">Production</span>
              </label>
              <Menu as="div" className="relative flex-shrink-0">
                <div>
                  <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src={`https://ui-avatars.com/api/?background=random&name=${firebase.auth().currentUser?.displayName || "Y"
                        }`}
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-4 py-3">
                      <p className="text-sm">Connecté en tant que</p>
                      <p className="text-sm font-medium text-center text-gray-900 truncate">
                        {firebase.auth().currentUser?.email}
                      </p>
                    </div>
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <NavLink
                            to={item.path}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </NavLink>
                        )}
                      </Menu.Item>
                    ))}
                    {
                      <Menu.Item>
                        {({ active }) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <div
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                            )}
                            onClick={() => firebase.auth().signOut()}
                          >
                            Se déconnecter
                          </div>
                        )}
                      </Menu.Item>
                    }
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        {/* Main content */}

        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="px-2 py-6 max-w-7xl md:px-6 2xl:max-w-screen-2xl">
            <Outlet />
          </div>
        </main>

      </div>
    </div >
  );
}

export default Root;
