
import { Fragment, useEffect, useState } from "react";
import FormInput from "../../../components/FormInput";
import Alert from "../../../components/Alert";
import { auth, googleAuthProvider } from "../../../firebase";
import { signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth'
import Form from "../../../components/Form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import firebase from "firebase/compat/app";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

function Login() {
    const [signInError, setSignInError] = useState<any>()
    const navigate = useNavigate();
    const signInAppWithEmailAndPassword = async (data: any) => {
        return toast.promise(signInWithEmailAndPassword(auth, data.email, data.password).then(() => {
        }),
            {
                loading: "Connexion...",
                success: "Connexion réussie",
                error: (err) => `${err.response.data.message}`,
            })
    }
    return (
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8 items-center mt-12">
            <div className="max-w-sm flex flex-col w-full">
                <div className="sm:mx-auto sm:w-full sm:max-w-md justify-center">
                    <div className="flex justify-center">
                    </div>
                    <h2 className="mt-6 mb-4 text-2xl font-bold tracking-tight text-gray-900 text-center">
                        Connexion Yenga Pay
                    </h2>
                </div>

                {signInError && (
                    <Alert
                        type="danger"
                        title="Erreur"
                        content={signInError.message}
                    />
                )}
                <Form
                    submitButtonFullWidth
                    onSubmit={signInAppWithEmailAndPassword}
                    form={{
                        resolver: yupResolver(
                            yup.object().shape({
                                email: yup
                                    .string()
                                    .email()
                                    .required(`L'adresse email est requis`),
                                password: yup
                                    .string()
                                    .required(`Le mot de passe est requis`)
                                    .min(6,
                                        `Le mot de passe doit avoir au moins 6 caractères`,
                                    ),
                            })
                        ),
                    }}
                    submitButtonLabel="Connexion"
                >
                    <FormInput name="email" label="Email" type="email" />
                    <FormInput name="password" label="Mot de passe" type="password" />
                </Form>
                <p className="text-blue-400 hover:underline hover:cursor-pointer text-xs ml-1 flex justify-end" onClick={() => { navigate('/auth/forgot-password') }}>Mot de passe oublé !</p>
                <p className="text-xs flex justify-center mt-4">Vous n'avez de compte ?<p className="text-blue-400 ml-2 hover:underline hover:cursor-pointer text-xs" onClick={() => { navigate('/auth/register') }}>Créez un compte !</p></p>
            </div>


        </div>
    );
}



export default Login