/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from 'react';
import { Route, Navigate, useNavigate } from 'react-router-dom';
// import { UserContext } from '../contexts';
// import useHasRoles from '../hooks/useHasRoles';
// import { LoadingSkeleton } from '@k-messaging-gateway/shared/ui-core';
import { auth, googleAuthProvider, db } from "../firebase";
import firebase from "firebase/compat/app";
import { UserContext } from '../context';
import { LoadingSkeleton } from './LoadingSkeleton'

export interface PrivateRouteProps {
  path: string;
  userRole?: 'SUDO' | 'USER';
  groupRoles?: ('OWNER' | 'ADMIN' | 'MEMBER')[];
  [x: string]: any;
  children: JSX.Element;
}
export default function AuthRoute(props: PrivateRouteProps): JSX.Element {
  const { user, isLoading } = useContext(UserContext);
  const { path } = props;
  // const { isAllowed } = useHasRoles();
  // const { path } = props;
  // if (isLoading) {
  //   return <LoadingSkeleton />;
  // }

  // Local signed-in state.
  // const { isAllowed } = useHasRoles();
  if (isLoading) { return <LoadingSkeleton /> }

  if (user && auth.currentUser?.emailVerified) {
    if (
      [
        '/auth/login',
        '/auth/register',
        '/auth/verify-email',
        '/auth/forgot-password',
      ].includes(path)
    ) {
      return (
        <Navigate
          to={{
            pathname: '/dashboard',
          }}
        />
      );
    }
    return props.children;
  }

  if (!user || !auth.currentUser?.emailVerified) {
    if (
      [
        '/auth/login',
        '/auth/register',
        '/auth/verify-email',
        '/auth/forgot-password',
      ].includes(path)
    ) {
      return props.children;
    } else {
      return <Navigate to={{
        pathname: '/auth/login',
      }} />
    }

  }

  // if (!auth.currentUser?.emailVerified) {
  //   return (<Navigate to={{ pathname: "/auth/verify-email" }} />)
  // }

  //toast.error('Please logic to access to this page');
  return <Navigate to="/auth/login" />;
  // return props.children
}
